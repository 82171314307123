import { UserRoleEnum } from './shared.model';

export enum ActivityType {
  Call = 1,
  ScheduledCall = 2,
  History = 3,
  InternalNote = 4,
  TextMessage = 5,
  Email = 6
}

export enum EmailActivityType {
  PasswordRecovery = 4,
  TermsOfServiceAccepted = 6,
  ReminderToResponseCenter = 7,
  ScheduledReport = 8,
  EmailChangeRequested = 19,
  FlowActivity = 25,
  NewPortalUserInvitation = 30,
  ManagerIncidentFeedback = 33,
  DeveloperAlert = 34,
  MonthlyReport = 39,
  HowdyFeedback = 42
}

export interface IncidentActivity {
  Id: number;
  Type: ActivityType;
  Date: Date;
  ToNumber: string;
  State: string;
  Content: string;
  Direction: string;
  ToPerson: string;
  ByPerson: string;
  ScheduledDate: Date;
}

export interface CauseCode {
  Id: string;
  L10nKey: string;
  GroupLevel1: string;
  GroupLevel2: string;
  TranslatedLabel: string;
}

export interface Incident {
  Id: number;
  AssignedTo: string;
  CallsCount: number;
  CompletedCallsCount: number;
  CallsLimit: number;
  State: IncidentState;
  TypeString: string;
  Company: CompanyIncident;
  Department: IncidentDepartment;
  IncidentCauseCodes: CauseCode[];
  Manager: Manager;
  Locale?: string;
  CreatedOn: Date;
  ScheduledStart: Date;
  AnonymityCeased: boolean;
  ReferredToPsychologist: boolean;
  IncidentFeedback: IncidentFeedback;
  ResponseCenterId: number;
}

export interface CompanyIncident {
  Id: number;
  Name: string;
  ResponseCenterBriefingUrl: string;
}

export interface IncidentDepartment {
  Id: number;
  Division: string;
  DivisionId: number;
  Key: string;
  Name: string;
  Size: number;
}

export enum IncidentState {
  New = 0,
  Open = 1,
  Pending = 2,
  Solved = 3,
  Canceled = 4,
  CanceledByManager = 5,
  Scheduled = 6,
  Contacted = 7,
  FailedToContact = 8,
  OpenReminderSent = 9
}

export interface IncidentFeedback {
  ContactTime: string;
  FeedbackContent: string;
}

export enum IncidentCallState {
  Planned = 1,
  CallCompleted = 2,
  CallRescheduledByEmployee = 3,
  Failed = 4
}

export interface IncidentCall {
  Id: number;
  AssignedToUser: string;
  IncidentId: number;
  State: IncidentCallState;
  FromNumber: string;
  ToNumber: string;
}

export interface Manager {
  Id: number;
  Guid: string;
  Firstname: string;
  Lastname: string;
  Email: string;
  Mobile: string;
  Timezone: any;
  RoleType: UserRoleEnum;
  PrimaryContact: boolean;
  UserRoleId?: number;
  IsOwner?: boolean;
}

export interface LowScoreDepartmentMetrics {
  MonthStart: Date;
  DepartmentId: number;
  CompanyId: number;
  CompanyName: string;
  DepartmentName: string;
  HowdyScore: number;
  CompanyResponseCenterId: number;
  CompanyResponseCenterName: string;
  Managers: Manager[];
}
