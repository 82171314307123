import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { FeedbackService } from './feedback.service';
import { Injectable } from '@angular/core';
import { MENU_ITEMS } from '../pages/pages-menu';
import { NbMenuItem } from '@nebular/theme';
import { PermissionService } from './permission.service';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';
import { SurveyQuestionTypeEnum, UserPermissions } from '@howdy/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MenuService {

  initialized = false;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private translate: TranslateService,
    private permissionService: PermissionService,
    private feedbackService: FeedbackService
  ) { }

  initializeMenu() {
    if (this.initialized) {
      return;
    }

    combineLatest([
      this.sharedService.onLanguageChange$,
      this.permissionService.userPermissions$
    ]).subscribe(() => {
      this.initialized = true;
      this.refreshMenuItems(this.permissionService.userPermissions);
    });
  }

  private async refreshMenuItems(permissions: UserPermissions) {
    const userPreference = this.sharedService.userPreferences;
    const navModel = MENU_ITEMS;
    const loadedTranslations = this.translate.translations[this.translate.currentLang];

    if (!loadedTranslations) {
      await this.translate.onDefaultLangChange.toPromise();
    }

    navModel.splice(1);
    const dashboard = navModel[0];
    dashboard.title = this.translate.instant('Portal.Shell.MainMenu.Dashboard.Title');
    dashboard.hidden = !!userPreference.activeResponseCenterId || !userPreference.activeCompanyId;
    dashboard.expanded = true;

    const isReportModuleEnabled = this.sharedService.currentCompany?.Settings?.ShowReportsModule;
    const isFeedbackEnabled = this.sharedService.currentCompany?.Settings?.FeedbackModuleEnabled;
    const disablePIIForManagers = this.sharedService.currentCompany?.Settings?.DisablePIIForManagers;
    const surveys = await this.feedbackService.getCompanySurveys(true, true).pipe(first()).toPromise();

    dashboard.children = [
      {
        title: this.translate.instant('Portal.Shell.MainMenu.Dashboard.Overview.Title'),
        link: '/pages/dashboard/overview',
      },
      {
        title: this.translate.instant('Portal.Shell.MainMenu.Dashboard.Cases.Title'),
        link: '/pages/dashboard/cases',
      }
    ];

    if (isFeedbackEnabled) {
      dashboard.children = [
        ...dashboard.children,
        {
          title: this.translate.instant('Portal.Shell.MainMenu.Dashboard.FeedbackAnswers.Body.Title'),
          link: '/pages/dashboard/surveyanswers/body',
          hidden: !surveys.filter(x => x.Questions?.some(q => q.StepType === SurveyQuestionTypeEnum.Body)).length
        },
        {
          title: this.translate.instant('Portal.Shell.MainMenu.Dashboard.FeedbackAnswers.Other.Title'),
          link: '/pages/dashboard/surveyanswers/other',
          hidden: !surveys.filter(x => x.Questions?.every(q => q.StepType !== SurveyQuestionTypeEnum.Body)).length
        }
      ];
    }

    dashboard.children.push({
      hidden: (!userPreference.activeCompanyId || permissions.hasOnlyDepartmentManagerRights || permissions.hasReadOnlyRights)
        && !permissions.hasHowdyEmployeeRights && !permissions.hasCompanyAdminReadonlyRights,
      title: this.translate.instant('Portal.Shell.MainMenu.Dashboard.DrillDown.Title'),
      link: '/pages/dashboard/departments',
    });

    const otherMenuItems: NbMenuItem[] = [{
      title: this.translate.instant('Portal.Shell.MainMenu.Company.Title'),
      icon: 'building',
      hidden: !userPreference.activeCompanyId || permissions.hasOnlyDepartmentManagerRights || permissions.hasReadOnlyRights,
      children: [
        {
          title: this.translate.instant('Portal.Modules.Company.Tabs.Settings.Title'),
          link: '/pages/company/settings/company-settings',
        },
        {
          title: this.translate.instant('Portal.Modules.Company.Tabs.Integration.Title'),
          link: '/pages/company/settings/integrations',
        },
        {
          title: this.translate.instant('Portal.Modules.Company.Tabs.APICalls.Title'),
          link: '/pages/company/settings/api-calls',
        }
      ]
    },
    {
      title: this.translate.instant('Portal.Shell.MainMenu.Employees.Title'),
      icon: 'user',
      link: '/pages/employee',
      hidden: !userPreference.activeCompanyId
        || permissions.hasOnlyObserverRights
        || (permissions.hasOnlyDepartmentManagerRights && disablePIIForManagers)
    },
    {
      title: this.translate.instant('Portal.Modules.Incidents.Title'),
      icon: 'chart-line',
      hidden: !userPreference.activeResponseCenterId,
      children: [
        {
          title: this.translate.instant('Portal.Modules.Incidents.Tabs.Overview.Title'),
          link: '/responsecenter/incidents/overview',
        }
      ]
    },
    {
      title: this.translate.instant('Portal.Shell.MainMenu.Reports.Title'),
      icon: 'file',
      hidden: !(isReportModuleEnabled && userPreference.activeCompanyId && permissions.hasCompanyAdminRights),
      children: [
        {
          title: this.translate.instant('Portal.Shell.MainMenu.Reports.Overview.Title'),
          link: '/pages/report/overview',
        },
        {
          title: this.translate.instant('Portal.Shell.MainMenu.Reports.AwaitingApproval.Title'),
          link: '/pages/report/awaitingapproval',
        }
      ]
    },
    {
      title: this.translate.instant('Portal.Shell.MainMenu.Users.Title'),
      icon: 'user-lock',
      link: '/pages/user',
      hidden: !((permissions.hasUserAdminRights || permissions.hasHowdyEmployeeRights) && userPreference.activeCompanyId)
    },
    {
      title: this.translate.instant('Portal.Shell.MainMenu.Feedback'),
      icon: 'clipboard-check',
      link: '/pages/feedback',
      hidden: !(userPreference.activeCompanyId && (permissions.hasCompanyAdminRights || permissions.hasHowdyEmployeeRights))
    },
    {
      title: this.translate.instant('Portal.Shell.MainMenu.Resources.Title'),
      icon: 'lightbulb',
      link: '/pages/resources',
      hidden: !userPreference.activeCompanyId
    },
    {
      title: 'Administration',
      group: true,
      hidden: !permissions.hasSystemAdminRights || !permissions.hasHowdyEmployeeRights
    },
    {
      title: 'Tools',
      icon: 'cog',
      hidden: !(permissions.hasSystemAdminRights || permissions.hasHowdyEmployeeRights),
      children: [
        {
          title: this.translate.instant('Portal.Shell.MainMenu.CompanyList.Title'),
          link: '/pages/admin/company-list'
        },
        {
          title: this.translate.instant('Portal.Shell.MainMenu.LabelEditor.Title'),
          link: '/pages/admin/l10neditor'
        },
        {
          title: this.translate.instant('Portal.Shell.MainMenu.SettingsEditor.Title'),
          link: '/pages/admin/settings-list',
          hidden: !permissions.hasSystemAdminRights
        },
        {
          title: 'Push notification',
          link: '/pages/admin/push-notification',
          hidden: !userPreference.activeCompanyId || !permissions.hasSystemAdminRights
        },
        {
          title: 'Email test',
          link: '/pages/admin/email-test',
          hidden: !(permissions.hasResponseCenterAdminRights || permissions.hasHowdyEmployeeRights)
        },
        {
          title: 'Intervention rating',
          link: '/pages/admin/intervention-test',
          hidden: !(permissions.hasResponseCenterAdminRights || permissions.hasHowdyEmployeeRights)
        },
        {
          title: 'Create coaching cases',
          link: '/pages/admin/manager-incident',
          hidden: !(permissions.hasResponseCenterAdminRights || permissions.hasHowdyEmployeeRights)
        },
        {
          title: 'Coaching overview',
          link: '/pages/admin/manager-coaching',
          hidden: !(permissions.hasResponseCenterAdminRights || permissions.hasHowdyEmployeeRights)
        }
      ]
    }];

    const allMenuItems = [dashboard, ...otherMenuItems];

    this.fixSelectionAfterPageReload(dashboard, otherMenuItems);
    this.setParentOfAllChildrenMenuItems(allMenuItems);

    if (navModel.length === 1) {
      navModel.push(...otherMenuItems);
    }
  }

  private fixSelectionAfterPageReload(dashboard: NbMenuItem, otherMenuItems: NbMenuItem[]) {
    const activeUrl = this.router.url;

    dashboard.children.forEach(child => {
      if (!!child?.link) {
        child['selected'] = activeUrl === child.link;
      }
    });

    otherMenuItems.forEach(item => {
      if (!!item?.link) {
        item['selected'] = activeUrl === item.link;
      } else {
        item.children?.forEach(child => {
          child['selected'] = activeUrl === child?.link;
        });
      }
    });
  }

  private setParentOfAllChildrenMenuItems(menuItems: NbMenuItem[]) {
    menuItems.forEach(item => {
      this.setParent(item);
    });
  }

  private setParent(item: NbMenuItem) {
    if (item.children) {
      item.children.forEach(child => {
        child.parent = item;
        this.setParent(child);
      });
    }
  }
}
